import React, { FC } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorTexts } from '@/types';
import { COLORS, LINKS, REGEXPS, TYPOGRAPHY } from '@/constants';
import { useAppDispatch } from '@/store/hooks';
import { useForm } from 'react-hook-form';
import { resetPasswordThunk } from '@/store/ducks/auth/thunks';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Container, TitleWithBackButton } from '@/components';
import { Button, InputWithLabel } from '@/ui/components';

interface FormData {
  snils: 'string';
  password: 'string';
  passwordConfirmation: 'string';
}

const schema = yup
  .object({
    snils: yup
      .string()
      .matches(REGEXPS.snilsRegExp, ErrorTexts.incorrectSnilsNumber)
      .required(ErrorTexts.required),
    password: yup
      .string()
      .min(6, ErrorTexts.lengthPassword)
      .required(ErrorTexts.required),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], ErrorTexts.confirmPassword),
  })
  .required();

const ResetPassword: FC = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitting, isValid, isDirty },
    getFieldState,
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const isSuccessField = (field: keyof FormData) =>
    getFieldState(field, formState).isDirty && !errors[field];

  const onSubmit = handleSubmit(async (data) => {
    const neededData = {
      newPassword: data.password,
      snils: data.snils,
    };

    try {
      const result = await dispatch(resetPasswordThunk(neededData)).unwrap();
      if (result) {
        navigate(LINKS.singIn);
      }
    } catch (rejectedValueOrSerializedError) {}
  });

  return (
    <Root>
      <StyledContainer>
        <Wrapper>
          <StyledTitleWithBackButton text="Восстановить пароль" />
          <form onSubmit={onSubmit}>
            <Inputs>
              <StyledInputWithLabel
                label="СНИЛС"
                name="snils"
                placeholder="123-456-789-01"
                mask="999-999-999 99"
                errorMessage={errors.snils?.message}
                isSuccess={isSuccessField('snils')}
                {...register('snils')}
              />
              <StyledInputWithLabel
                label="Пароль"
                name="password"
                type="password"
                placeholder="Пароль"
                errorMessage={errors.password?.message}
                isSuccess={isSuccessField('password')}
                {...register('password')}
              />
              <StyledInputWithLabel
                label="Подтвердить пароль"
                name="passwordConfirmation"
                type="password"
                placeholder="Подтвердить пароль"
                errorMessage={errors.passwordConfirmation?.message}
                isSuccess={isSuccessField('passwordConfirmation')}
                {...register('passwordConfirmation')}
              />
            </Inputs>
            <StyledButton
              type="submit"
              disabled={!isDirty || !isValid}
              isLoading={isSubmitting}
            >
              Создать новый пароль
            </StyledButton>
          </form>
        </Wrapper>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.grayLighter};
`;

const StyledContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 80px;
  @media (max-width: 1024px) {
    padding-bottom: 64px;
  }
`;

const Wrapper = styled.div`
  background-color: ${COLORS.white};
  border-radius: 18px;
  padding: 48px;
  max-width: 480px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding: 0;
    background-color: transparent;
  }
`;

const StyledTitleWithBackButton = styled(TitleWithBackButton)`
  margin-bottom: 32px;
  @media (max-width: 600px) {
    ${TYPOGRAPHY.headline1Medium24};
  }
`;

const Inputs = styled.div`
  margin-bottom: 32px;
`;

const StyledInputWithLabel = styled(InputWithLabel)`
  margin-bottom: 24px;
  width: 100%;
  :last-child {
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  max-width: none;
`;

export default ResetPassword;
