import React from 'react';
import { MainLayout } from '@/layouts';
import { MainHeading, Seo } from '@/components';
import { SeoData } from '@/types';
import retireds from '@/assets/images/retireds.png';
import { COLORS } from '@/constants';
import { SignIn } from '@/pageComponents/SignInPage';
import { ResetPassword } from '@/pageComponents/ResetPasswordPage';

export default function ResetPasswordPage() {
  const seoData: SeoData = {
    metaTitle: 'Социальный проект «Выплаты»',
    metaDescription: 'Социальный проект «Выплаты»',
    keywords: 'пенсия, деньги, выплата',
    sharedImage: {
      url: retireds,
    },
  };

  return (
    <MainLayout backgroundColor={COLORS.grayLighter} fullHeight={true}>
      <Seo data={seoData} />
      <MainHeading text={seoData.metaTitle} />
      <ResetPassword />
    </MainLayout>
  );
}
